import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;

  .kfwx {
    position: absolute;
    bottom: 0px;
    left: 10px;
    width: 96px;
    height: 126px;
    .smtj {
      width: 96px;
      height: 126px;
      background: linear-gradient(180deg, #74e2fd -113.7%, #fefefe 117.71%);
      box-shadow: 0px 0px 8px 0px #00000026;
      border-radius: 4px;

      font-family: 'SourceHanSansCN';
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 3%;
      color: #0C1939;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  color: #0c1939;
  transition: all 0.2s ease-out 0s;
  .nav-wrap {
    width: 100%;
    max-width: 1312px;
    padding: 0 16px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-wrap {
      display: flex;
      align-items: center;
      .logo {
        width: 134px;
        cursor: pointer;
        margin-right: 32px;
      }
      .menu-list {
        display: flex;
        align-items: center;
        position: relative;
        .menu-item {
          font-family: "SourceHanSansCN-Medium";
          margin-left: 64px;
          cursor: pointer;
          .arrow-svg-icon {
            transition: transform 0.24s;
          }
          &.expend {
            .arrow-svg-icon {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
    .right-wrap {
      display: flex;
      align-items: center;
    }
  }
  &.header-white {
    color: #fff;
  }
  &.header-sticky {
    position: sticky;
    background: #fff;
    color: #0c1939;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 10px -10px;
    z-index: 2;
  }
  @media (max-width: 1024px) {
    .nav-wrap {
      .left-wrap {
        .menu-list {
          .menu-item {
            margin-left: 32px;
          }
        }
      }
    }
  }
  @media (max-width: 860px) {
    .nav-wrap {
      .left-wrap {
        .menu-list {
          .menu-item {
            margin-left: 12px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .wxkf {
      position: absolute;
      bottom: 100;
      right: 50;
      width: 50px;
      height: 50px;
      .logo-wx {
        width: 50px;
        height: 50px;
      }
    }
    .nav-wrap {
      width: 100%;
      height: 60px;
      max-width: none;
      padding: 0 20px;
      .left-wrap {
        .logo {
          width: 126px;
        }
      }
      .right-wrap {
        .menu-btn {
          width: 24px;
          height: auto;
          fill: currentColor;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const Content = styled.div``;

export const Footer = styled.div``;
